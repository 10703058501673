export const ENV = process.env.REACT_APP_ENV ?? "production";
export const IS_LOCAL = ENV === "local";
export const IS_PROD = ENV === "production";

export const GCP_PROJECT_REGION = process.env.REACT_APP_GCP_PROJECT_REGION ?? "us-central1";
export const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID ?? "interflexion-svc";
export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;
export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID;
export const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
export const FIREBASE_DATABASE_URL = process.env.REACT_APP_FIREBASE_DATABASE_URL;
export const FIREBASE_MEASUREMENT_ID = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;
export const FIREBASE_MESSAGING_SENDER_ID = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
export const FIREBASE_STORAGE_BUCKET =
  process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || "interflexion-test-5daa2.appspot.com";

export const FIREBASE_LOCAL_AUTH_EMULATOR_URL =
  process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_HOST ?? "http://localhost:9090";

export const FIREBASE_LOCAL_STORAGE_EMULATOR_URL =
  process.env.REACT_APP_FIREBASE_STORAGE_EMULATOR_HOST ?? "localhost";

export const gcpFunctionRoot = `https://${GCP_PROJECT_REGION}-${FIREBASE_PROJECT_ID}.cloudfunctions.net`;
export const gcpApiRoot = `${gcpFunctionRoot}/api`;
export const gcpEmbedApiRoot = `${gcpFunctionRoot}/embed`;

export const localFunctionRoot = `http://localhost:5001/${FIREBASE_PROJECT_ID}/${GCP_PROJECT_REGION}`;
export const localApiRoot = `${localFunctionRoot}/api`;
export const localEmbedApiRoot = `${localFunctionRoot}/embed`;

export const functionRoot = IS_LOCAL ? localFunctionRoot : gcpFunctionRoot;
export const apiRoot = IS_LOCAL ? localApiRoot : gcpApiRoot;
export const embedRoot = IS_LOCAL ? localEmbedApiRoot : gcpEmbedApiRoot;

export const isProd = FIREBASE_PROJECT_ID === "interflexion-svc" || ENV === "production";

export const IFX_SUPPORT_EMAIL = "support@interflexion.com";

// NOTE: this must be kept in sync with any changes to the ifx_privilege table
export const HRBP_PRIVILEGE_ID = 500;

// export const SENTRY_DSN =
//   "https://6d0f7bfc415b464c8230502ec017da53@o4504039970177024.ingest.sentry.io/4504040020443136";

// File types
export const ALLOWED_VIDEO_FILE_EXTENSIONS = process.env.ALLOWED_VIDEO_FILE_EXTENSIONS?.split(
  " "
) || [".mp4", ".m4v"]; // temp solution until we move to mimetypes
export const ALLOWED_AUDIO_FILE_EXTENSIONS = process.env.ALLOWED_AUDIO_FILE_EXTENSIONS?.split(
  " "
) || [".mp3"]; // temp solution until we move to mimetypes

// Embedded interact screen support
export const INTERACT_USER_EMAIL = process.env.REACT_APP_INTERACT_USER_EMAIL || "";
export const INTERACT_USER_PASSWORD = process.env.REACT_APP_INTERACT_USER_PASSWORD || "";
export const INTERACT_EMBED_URL = process.env.REACT_APP_INTERACT_EMBED_URL || "";
